import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage';
import "firebase/firestore";
import "firebase/auth";

// Initialize Firebase
firebase.initializeApp({
    apiKey: "AIzaSyCCZ-l6w1l3GFCSzE63LfLXex1TBHghTug",
    authDomain: "jj-univ-nrc.firebaseapp.com",
    projectId: "jj-univ-nrc",
    storageBucket: "jj-univ-nrc.appspot.com",
    messagingSenderId: "692374552627",
    appId: "1:692374552627:web:cd8a2aeb033f67a94e6346",
    measurementId: "G-7E0XDEGF2G"
});
// jj-univ-nrc-dev 테스트서버
// firebase.initializeApp({
//     apiKey: "AIzaSyDPy1aaOnJDXCB8BFnadjHIYwnPj8IoZOI",
//     authDomain: "jj-univ-nrc-dev.firebaseapp.com",
//     projectId: "jj-univ-nrc-dev",
//     storageBucket: "jj-univ-nrc-dev.appspot.com",
//     messagingSenderId: "218342501019",
//     appId: "1:218342501019:web:49613eb2bd06ac4444f43e",
//     measurementId: "G-48FS2FS5TE"
// });

firebase.auth().languageCode = 'ko'

const auth = firebase.auth()
const firestore = firebase.firestore()
const functions = firebase.app().functions('asia-northeast3')

export {auth, firestore, functions, firebase}






