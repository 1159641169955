import Vue from 'vue'
import Router from 'vue-router'
import {firebase} from "../firebase/firebaseConfig";
import "firebase/auth";
import store from "../store";

Vue.use(Router)


const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            // =============================================================================
            // COMMON LAYOUT ROUTES
            // =============================================================================
            path: '',
            component: () => import('@/layouts/common/CommonView.vue'),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: '/',
                    name: 'root',
                    component: () => import('../views/Home'),
                },


            ],
        }, {
            // =============================================================================
            // ACCOUNT LAYOUT ROUTES
            // =============================================================================
            path: '',
            component: () => import('@/layouts/account/AccountView.vue'),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: '/login',
                    name: 'login',
                    component: () => import('../views/account/Login'),
                },
                {
                    path: '/findId',
                    name: 'findId',
                    component: () => import('../views/account/FindId'),
                },
                {
                    path: '/findPassword',
                    name: 'findPassword',
                    component: () => import('../views/account/FindPassword'),
                },


            ],
        }, {
            // =============================================================================
            // ADD USER LAYOUT ROUTES
            // =============================================================================
            path: '',
            component: () => import('@/layouts/account/AddUserView.vue'),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: '/signUp',
                    name: 'signUp',
                    component: () => import('../views/account/SignUp'),
                },
            ],
        },
        {
            // =============================================================================
            // LOGIN USER LAYOUT ROUTES
            // =============================================================================
            path: '',
            component: () => import('@/layouts/account/LoginView.vue'),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: '/intro',
                    name: 'intro',
                    component: () => import('../views/Intro'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },
                {
                    path: '/kidsList',
                    name: 'kidsList',
                    component: () => import('@/views/evaluation/operation/kids/KidsListView'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },
                {
                    path: '/choice/:id',
                    name: 'choice',
                    component: () => import('@/views/evaluation/operation/EvaluationChoice'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },
                {
                    path: '/online/:id',
                    name: 'onlineGuide',
                    component: () => import('@/views/evaluation/operation/online/OnlineGuide'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },
                {
                    path: '/online/:id/:evaluationId',
                    name: 'online',
                    component: () => import('@/views/evaluation/operation/online/Online'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },
                {
                    path: '/offline/:id',
                    name: 'offlineHide',
                    component: () => import('@/views/evaluation/operation/offline/OfflineHide'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },
                {
                    path: '/offline/:id/:evaluationId',
                    name: 'offline',
                    component: () => import('@/views/evaluation/operation/offline/Offline'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },
                {
                    path: '/manage',
                    name: 'evaluationManage',
                    component: () => import('@/views/evaluation/manage/EvaluationManage'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },
                {
                    path: '/manage/tab1/:id',
                    name: 'activityProfile',
                    component: () => import('@/views/evaluation/manage/tab1/Tab1'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },
                {
                    path: '/manage/tab2/:id',
                    name: 'analysisByRegion',
                    component: () => import('@/views/evaluation/manage/tab2/Tab2'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },
                {
                    path: '/manage/tab3/:id',
                    name: 'analysisByActivity',
                    component: () => import('@/views/evaluation/manage/tab3/Tab3'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },
                {
                    path: '/qna',
                    name: 'qnaList',
                    component: () => import('../views/qna/QnaListView.vue'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },
                {
                    path: '/qna/create',
                    name: 'qnaCreate',
                    component: () => import('../views/qna/QnaCreateView.vue'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },
                {
                    path: '/qna/:id',
                    name: 'qnaDetail',
                    component: () => import('../views/qna/QnaDetailView.vue'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },
                {
                    path: '/qna/:id/edit',
                    name: 'qnaEdit',
                    component: () => import('../views/qna/QnaEditView.vue'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },
                {
                    path: '/reference',
                    name: 'referenceList',
                    component: () => import('../views/reference/ReferenceListView'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },
                {
                    path: '/reference/create',
                    name: 'referenceCreate',
                    component: () => import('../views/reference/ReferenceCreateView.vue'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },
                {
                    path: '/reference/:id/edit',
                    name: 'referenceEdit',
                    component: () => import('../views/reference/ReferenceEditView.vue'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },
                {
                    path: '/reference/:id',
                    name: 'referenceDetail',
                    component: () => import('../views/reference/ReferenceDetailView.vue'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },

            ],
        },
        {
            // =============================================================================
            // LOGIN USER LAYOUT ROUTES
            // =============================================================================
            path: '',
            component: () => import('@/layouts/account/MyInfoOnlyView.vue'),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: '/myInfo',
                    name: 'myInfo',
                    component: () => import('../views/myInfo/MyInfo'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },
                {
                    path: '/rePassword',
                    name: 'rePassword',
                    component: () => import('../views/account/RePassword'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },
                {
                    path: '/removeMyInfo',
                    name: 'removeMyInfo',
                    component: () => import('@/views/myInfo/RemoveMyInfo'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },
            ],
        },
        {
            // =============================================================================
            // FULL LAYOUT ROUTES
            // =============================================================================
            path: '',
            component: () => import('@/layouts/full_screen/FullScreenView.vue'),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================

                {
                    path: '/master/login',
                    name: 'masterLogin',
                    component: () => import('../views/master/account/MasterLogin'),
                },
                {
                    path: '/test',
                    name: 'test',
                    component: () => import('../views/Test'),
                    meta: {
                        authRequired: false,
                        level: 0,
                    }
                },

                {
                    path: '/pages/error-404',
                    name: 'pages-error-404',
                    component: () => import('../views/Error404'),
                    meta: {
                        title: '404',
                    }
                },
            ],
        },
        {
            // =============================================================================
            // MASTER LAYOUT ROUTES
            // =============================================================================
            path: '',
            component: () => import('@/layouts/master/MasterView.vue'),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: '/master',
                    name: 'master',
                    redirect: '/master/main'
                },
                {
                    path: '/master/main',
                    name: 'masterMain',
                    component: () => import('../views/master/kidsInfo/KidsInfo'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/evaluationInfo',
                    name: 'evaluationInfo',
                    component: () => import('../views/master/evaluationInfo/EvaluationInfo'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/qnaManage',
                    name: 'qnaManage',
                    component: () => import('../views/master/qnaManage/QnaManage'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/qnaManage/:id',
                    name: 'qnaManageDetail',
                    component: () => import('../views/master/qnaManage/QnaDetailPage'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
            ],
        },
        {
            path: '*',
            redirect: '/pages/error-404'
        }
    ],
    scrollBehavior() {
        return {x: 0, y: 0}
    },
})

const makeTitle = (title) =>
    title ? `${title} | Measure for Children’s Participation` : "Measure for Children’s Participation";

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = makeTitle(to.meta.title);
    });
});

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => {
        if (err.name !== 'NavigationDuplicated') throw err
    })
}

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
    firebase.auth().onAuthStateChanged(async (user) => {
        await store.dispatch('getUser', user)
            .then(() => {
                if (user) {
                    // console.log(user)
                } else {
                    // console.log('없다.')
                }
            })

        const firebaseCurrentUser = firebase.auth().currentUser
        // console.log(store.state.token)
        // console.log(store.state.user)

        if (to.meta.authRequired) {
            if (!(firebaseCurrentUser)) {
                if (to.meta.level === 0) {
                    await router.push({path: '/master/login'}).catch(() => true)
                } else if (to.meta.level === 1) {
                    await router.push({path: '/login'}).catch(() => true)
                } else {
                    if (firebaseCurrentUser.email.includes('@mfcp.com')) {
                        if (to.path !== '/master/main')
                            await router.push({path: '/master/main'}).catch(() => true)
                    }
                }
            } else {
                if (firebaseCurrentUser.photoURL === '1') {
                    if (to.meta.level === 0) {
                        if (to.path !== '/intro')
                            await router.push({path: '/intro'}).catch(() => true)
                    }
                } else if (firebaseCurrentUser.photoURL === '0') {
                    if (to.meta.level === 1) {
                        if (to.path !== '/master/main')
                            await router.push({path: '/master/main'}).catch(() => true)
                    }
                } else {
                    if (firebaseCurrentUser.email.includes('@mfcp.com')) {
                        if (to.meta.level === 1) {
                            if (to.path !== '/master/main')
                                await router.push({path: '/master/main'}).catch(() => true)
                        }
                    }
                }
            }
        } else {
            if (user) {
                if (firebaseCurrentUser.photoURL === '1') {
                    if (to.path !== '/intro' && to.path !== '/')
                        await router.push({path: '/intro'}).catch(() => true)
                } else if (firebaseCurrentUser.photoURL === '0') {
                    if (to.path !== '/master/main' && to.path !== '/')
                        await router.push({path: '/master/main'}).catch(() => true)
                } else {
                    if (firebaseCurrentUser.email.includes('@mfcp.com')) {
                        if (to.path !== '/master/main' && to.path !== '/')
                            await router.push({path: '/master/main'}).catch(() => true)
                    }
                }
            }
        }

        if (store.state.firebaseLoaded)
            next()
    })

});


export default router
